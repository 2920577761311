export async function getRecaptcha(action: string): Promise<string> {
	if (typeof grecaptcha === "undefined") {
		console.error("reCAPTCHA is not loaded");
		return ""; // ou pode retornar uma string vazia se preferir
	}

	try {
		// Aguardar o reCAPTCHA estar pronto
		await new Promise<void>((resolve) => grecaptcha.enterprise.ready(resolve));

		// Gerar o token com base na ação
		const token: Promise<string> = await grecaptcha.enterprise.execute(
			process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
			{ action },
		);

		return token;
	} catch (error) {
		console.error("Error obtaining reCAPTCHA token:", error);
		return ""; // ou "" se preferir retornar uma string vazia em caso de erro
	}
}
