import { AuthContext } from "@/bet-nextv1/context/AuthContext";
import redirectTo, { useActiveRoute } from "@/bet-nextv1/utils/redirectTo";
import { useSubCore } from "@/hooks/useSubCore";
import {
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { useTranslation } from "next-i18next";
import router from "next/router";
import { useContext } from "react";
import { RiCoupon3Fill } from "react-icons/ri";
import { TbLogout } from "react-icons/tb";
import styles from "./styles.module.scss";

type TItemProps = {
	title: string;
	path: string;
	imagePath: string | undefined;
	toggleDrawer: (open: boolean) => void;
	icon?: JSX.Element;
};

function Item({ path, title, imagePath, icon, toggleDrawer }: TItemProps) {
	const { setOpenLoginModal } = useSubCore();
	const { user } = useContext(AuthContext);

	const handleclick = (route: string) => {
		toggleDrawer(false);
		redirectTo(route, user, router, setOpenLoginModal);
	};
	const { isActive, subActive } = useActiveRoute(path);
	const className = `${styles.listText} ${isActive || subActive ? "!text-primary" : ""}`;
	const { t } = useTranslation();

	return (
		<ListItem className={styles.item}>
			<ListItemButton>
				{/* <Link href='/deposit/?tabIndex=1'> */}
				<a onClick={() => handleclick(path)}>
					<Box className={styles.box}>
						<ListItemIcon className={`${styles.icon} flex items-center`}>
							{icon ? icon : <img src={imagePath} alt={title} />}
						</ListItemIcon>
						<ListItemText className={className}>{t(title)}</ListItemText>
					</Box>
				</a>
			</ListItemButton>
		</ListItem>
	);
}

type Props = {
	handleLogoutClick: VoidFunction;
	toggleDrawer: (open: boolean) => void;
};
export const DrawerLeft = ({ handleLogoutClick, toggleDrawer }: Props) => {
	const { t } = useTranslation();
	const { user } = useContext(AuthContext);

	return (
		<List className={styles.drawerList}>
			<Item
				path="/liveCasino"
				imagePath="/assets/icon/Live-Casino.png"
				title="live casino"
				toggleDrawer={toggleDrawer}
			/>

			<Item
				path="/slotsCasino"
				imagePath="/assets/icon/Slot.png"
				title="슬롯게임"
				toggleDrawer={toggleDrawer}
			/>

			<Item
				path="/deposit/?tabIndex=0"
				imagePath="/assets/icon/Deposit.png"
				title="Depósito"
				toggleDrawer={toggleDrawer}
			/>

			<Item
				path="/deposit/?tabIndex=1"
				imagePath="/assets/icon/Withdraw.png"
				title="Saque"
				toggleDrawer={toggleDrawer}
			/>

			<Item
				path="/deposit/?tabIndex=2"
				imagePath="/assets/icon/Money-Exchange.png"
				title="Transferencias"
				toggleDrawer={toggleDrawer}
			/>

			<Item
				path="/coupon"
				imagePath={undefined}
				title="Caixa de cupom"
				icon={<RiCoupon3Fill className="!size-[12px] ml-9 " />}
				toggleDrawer={toggleDrawer}
			/>

			<Item
				path="/event"
				imagePath="/assets/icon/Event.png"
				title="Evento"
				toggleDrawer={toggleDrawer}
			/>

			{/* <ListItem className={styles.item}>
            <ListItemButton>
                <a onClick={() => handleclick("/deposit/?tabIndex=7")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            <img src="/assets/icon/Indications.png" alt="" />
                        </ListItemIcon>
                        <ListItemText  className={styles.listText}>
                            {t("Rules")}
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem> */}

			<Item
				path="/deposit/?tabIndex=7"
				imagePath="/assets/icon/News.png"
				title="Rules"
				toggleDrawer={toggleDrawer}
			/>

			<Item
				path="/deposit/?tabIndex=3"
				imagePath="/assets/icon/Message.png"
				title="Customer center"
				toggleDrawer={toggleDrawer}
			/>

			<Item
				path="/deposit/?tabIndex=8"
				imagePath="/assets/icon/Loosing-Bonus.png"
				title="루징신청"
				toggleDrawer={toggleDrawer}
			/>

			<Item
				path="/deposit/?tabIndex=4"
				imagePath="/assets/icon/Chat.png"
				title="쪽지함"
				toggleDrawer={toggleDrawer}
			/>

			{/* <ListItem className={styles.item}>
            <ListItemButton>
                <a onClick={() => redirectTo("/deposit/?tabIndex=7", user, router, setOpenLoginModal)}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            <img src="/assets/icon/News.png" alt="" />
                        </ListItemIcon>
                        <ListItemText  className={styles.listText}>
                           
                            지인추천
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem> */}

			<Item
				path="/perfil"
				imagePath="/assets/icon/My-Page.png"
				title="My page"
				toggleDrawer={toggleDrawer}
			/>

			<ListItem className={styles.item}>
				<ListItemButton>
					<a onClick={() => handleclick("/checking")}>
						<Box className={styles.box}>
							<ListItemIcon className={styles.icon}>
								<img src="/assets/icon/Calendar.png" alt="" />
							</ListItemIcon>
							<ListItemText className={styles.listText}>
								{t("Checkin")}
							</ListItemText>
						</Box>
					</a>
				</ListItemButton>
			</ListItem>
			<ListItem className={styles.item}>
				<ListItemButton>
					<a onClick={() => window.location.reload()}>
						<Box className={styles.box}>
							<ListItemIcon className={styles.icon}>
								<img src="/assets/icon/4208523.png" alt="reaload" />
							</ListItemIcon>
							<ListItemText className={styles.listText}>쿠키삭제</ListItemText>
						</Box>
					</a>
				</ListItemButton>
			</ListItem>

			{user && (
				<ListItem className={styles.item}>
					<ListItemButton>
						<div
							onClick={() => {
								toggleDrawer(false);
								handleLogoutClick();
							}}
						>
							<Box className={styles.box}>
								<ListItemIcon className={styles.icon}>
									<TbLogout />
								</ListItemIcon>
								<ListItemText className={styles.listText}>
									{t("Logout")}
								</ListItemText>
							</Box>
						</div>
					</ListItemButton>
				</ListItem>
			)}
		</List>
	);
};
