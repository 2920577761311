import { SUBTRANSLANTE_MAP } from "@/setting/setting";

export const subTranslate = (stringToTranslate, t) => {
	if (
		SUBTRANSLANTE_MAP?.[stringToTranslate as keyof typeof SUBTRANSLANTE_MAP]
	) {
		return SUBTRANSLANTE_MAP[
			stringToTranslate as keyof typeof SUBTRANSLANTE_MAP
		];
	}

	if (stringToTranslate?.includes("Cassino Primeiro deposito")) {
		return stringToTranslate?.replace(
			"Cassino Primeiro deposito",
			"카지노 첫충",
		);
	}

	if (stringToTranslate?.includes("Cassino Maechung")) {
		return stringToTranslate?.replace("Cassino Maechung", "	카지노 매충");
	}

	if (stringToTranslate?.includes("Slots First Deposit")) {
		return stringToTranslate?.replace("Slots First Deposit", "슬롯 첫충");
	}

	if (stringToTranslate?.includes("Slots Maechung")) {
		return stringToTranslate?.replace("Slots Maechung", "슬롯 매충");
	}

	return t(stringToTranslate);
};
