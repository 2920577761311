import { ImageSetBet } from "@/bet-nextv1/ImageSetBet";
import { getDocument } from "@/core-nextv3/document/document.api";
import { HOME_PAGE_SETTING } from "@/setting/setting";
import { Drawer } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { LuUser } from "react-icons/lu";
import { RxHamburgerMenu } from "react-icons/rx";
import logo from "../../../../public/assets/img/logo.png";
import { DrawerLeft } from "./DrawerLeft";
import { DrawerRight } from "./DrawerRight";
import styles from "./styles.module.scss";

export const HeaderMobile = ({
	account,

	handleLogoutClick,
}: any) => {
	const [open, setOpen] = useState(false);
	const [openRight, setOpenRight] = useState(false);
	const router = useRouter();
	const [bannersGames, setBannersGames] = useState<any>([]);

	const toggleDrawer = (newOpen: boolean) => {
		setOpen(newOpen);
	};

	const toggleDrawerRight = (newOpen: boolean) => {
		setOpenRight(newOpen);
	};

	const handleLogoLink = async (path: string) => {
		if (router.asPath === "/") {
			router.reload();
		} else {
			await router.push(path);
		}
	};

	const getHome = async () => {
		const res = await getDocument(HOME_PAGE_SETTING);

		setBannersGames(res?.data?.bannersGames);
	};

	useEffect(() => {
		getHome();
	}, []);

	return (
		<>
			<div className={styles.headerMobile}>
				<div className={styles.contentMobile}>
					<Drawer
						sx={{ ".MuiDrawer-paperAnchorLeft": { height: "100%" } }}
						open={open}
						onClose={() => toggleDrawer(false)}
					>
						<DrawerLeft
							toggleDrawer={toggleDrawer}
							handleLogoutClick={handleLogoutClick}
						/>
					</Drawer>

					<Drawer
						open={openRight}
						anchor={"right"}
						onClose={() => toggleDrawerRight(false)}
					>
						<DrawerRight
							toggleDrawer={toggleDrawerRight}
							bannersGames={bannersGames}
						/>
					</Drawer>

					<div className={styles.menuBtnMobile}>
						<div className={styles.menuBtnGrp}>
							<div className={styles.languageWrap}>
								<LuUser onClick={() => toggleDrawer(true)} />
							</div>
						</div>
					</div>

					<div className={styles.logoMenu}>
						<button onClick={() => handleLogoLink("/")} className={styles.logo}>
							<ImageSetBet src={account?.logo?._url || logo} alt="logo" />
						</button>
					</div>

					<div className={styles.menuBtnMobile}>
						<div className={styles.menuBtnGrp}>
							<div className={styles.languageWrap}>
								<RxHamburgerMenu onClick={() => toggleDrawerRight(true)} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
